<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import tableData from "@/components/tablecomponent";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
// import { _ } from "vue-underscore";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "แก้ไขข้อมูลรุ่นรถ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // tableData,
    Multiselect,
    // DatePicker,
  },
  // Multiselect, DatePicker,
  data() {
    return {
      // tableData: tableData,
      loading: false,
      isLoading: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      title: "เพิ่มข้อมูลรุ่นรถ (หรือแก้ไข)",
      rowsRequis: [],
      reqCode: "",
      submitform: false,
      rowsVehicleBrand: [],
      modelCode: "",
      modelYear: "",
      brands: "",
      items: [
        {
          text: "ข้อมูลหลัก",
          active: true,
        },
        {
          text: "ศูนย์บริการ",
          active: true,
        },
        {
          text: "รุ่นรถ",
          active: false,
          href: "/vehiclemodels",
        },
        {
          text: "เพิ่มข้อมูลรุ่นรถ (หรือแก้ไข)",
          active: true,
        },
      ],
      nameTh: "",
      nameEn: "",
      userBranch: "",
      fuel: "",
      transmission: "",
      gearAuto: "Auto",
      gearManual: "Manual",
      rowsDetailModel: [],
      fuelOptions: [
        {
          id: 1,
          nameTh: "เบนซิน",
          type: "S",
        },
        {
          id: 2,
          nameTh: "ดีเซล",
          type: "D",
        },
      ],
      transmissionOptions: [
        {
          id: 1,
          nameTh: "Auto",
          type: "AT",
        },
        {
          id: 2,
          nameTh: "Manual",
          type: "MT",
        },
      ],
    };
  },
  validations: {
    brands: {
      required,
    },
    nameEn: {
      required,
    },
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.userBranch = arrayBranch;
    this.optionBranch = user.userAccessBranch;
    this.userBranchId = user.branchId;
    if (this.$route.params.modelId != null) {
      this.getDataDetailModel();
    }
  },
  created() {
    this.getDataVehicleBrand();
  },
  methods: {
    updateModel: function() {
      if (this.brands != null) {
        this.overlayFlag = true;
        useNetw
          .put("api/vehicle-model/update", {
            modelId: this.$route.params.modelId,
            branchId: this.userBranchId,
            brandId: this.brands.brandId,
            modelCode: this.modelCode,
            nameTh: this.nameTh,
            nameEn: this.nameEn,
            modelYear: this.modelYear,
            transmission: this.transmission.type != null ? this.transmission.type: undefined,
            fuel: this.fuel.type,
          })
          .then((response) => {
            Swal.fire(
              appConfig.swal.title.postSuccess,
              JSON.stringify(response.data.message),
              appConfig.swal.type.success
            );
            this.$router.push({
              name: "vehiclemodels",
            });
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
    },
    submitBrand: function() {
      this.loading = true;
      const formDataModel = new FormData();
      formDataModel.append("branchId", this.userBranchId);
      formDataModel.append("brandId", this.brands.brandId);
      formDataModel.append("modelCode", this.modelCode);
      formDataModel.append("nameTh", this.nameTh);
      formDataModel.append("nameEn", this.nameEn);
      formDataModel.append("modelYear", this.modelYear);
      if (this.transmission.type != null) {
        formDataModel.append("transmission", this.transmission.type);
      }
      if (this.fuel.type != null) {
        formDataModel.append("fuel", this.fuel.type);
      }
      useNetw
        .post("api/vehicle-model/store", formDataModel)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
              name: "vehiclemodels",
            });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    getDataVehicleBrand: function() {
      if (this.brands != null) {
        this.isLoading = true;
        useNetw
          .get("api/master/vehicle/brands", {})
          .then((response) => {
            this.rowsVehicleBrand = response.data.data;
            // console.log(this.rowsVehicleBrand, "sdaasds");
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    },
    getDataDetailModel: function() {
      this.loading = true;
      useNetw
        .get("api/vehicle-model/show", {
          params: {
            modelId: this.$route.params.modelId,
          },
        })
        .then((response) => {
          this.rowsDetailModel = response.data.data;
          this.brands = {
            brandId: response.data.data.brandId,
            nameEn: response.data.data.brandNameEn,
          };
          this.nameTh = response.data.data.nameTh;
          this.modelCode = response.data.data.modelCode;
          this.nameEn = response.data.data.nameEn;
          this.modelYear = response.data.data.modelYear;
          if (response.data.data.transmission === "AT") {
            this.transmission = {
              type: response.data.data.transmission,
              nameTh: this.gearAuto,
            };
          } else {
            this.transmission = {
              type: response.data.data.transmission,
              nameTh: this.gearManual,
            };
          }
          this.fuel = { nameTh: response.data.data.fuel };
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitBrand();
      }
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Service List</h4> -->

            <!-- Search -->
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton-table
                  :rows="5"
                  :columns="6"
                  :table-props="{ bordered: false, striped: true }"
                  animation="throb"
                ></b-skeleton-table>
              </template>
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 mt-3">
                        <div class="row">
                          <div class="col-10 col-sm-6 col-md-3">
                            <div class="mb-3 position-relative">
                              <code> * </code>ยี่ห้อ:
                              <multiselect
                                :options="rowsVehicleBrand"
                                v-model="brands"
                                :close-on-select="true"
                                :internal-search="true"
                                open-direction="bottom"
                                :show-labels="false"
                                label="nameEn"
                                placeholder=""
                                :class="{
                                  'is-invalid': submitform && $v.brands.$error,
                                }"
                              >
                                <span slot="noResult">ไม่พบข้อมูล</span>
                              </multiselect>
                              <div
                                v-if="submitform && $v.brands.$error"
                                class="invalid-tooltip"
                              >
                                <span v-if="!$v.brands.required">{{
                                  errormessage
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-6 col-md-3">
                            <div class="mb-3 position-relative">
                              รหัสรุ่น:
                              <b-form-input
                                v-model="modelCode"
                                class="form-control"
                              ></b-form-input>
                            </div>
                          </div>
                          <div class="col-12 col-sm-6 col-md-3">
                            <div class="mb-3 position-relative">
                              ชื่อรุ่น (ไทย):
                              <b-form-input
                                v-model="nameTh"
                                class="form-control"
                              ></b-form-input>
                            </div>
                          </div>
                          <div class="col-12 col-sm-6 col-md-3">
                            <div class="mb-3 position-relative">
                              <code> * </code>ชื่อรุ่น (อังกฤษ):
                              <b-form-input
                                v-model="nameEn"
                                class="form-control"
                                :class="{
                                  'is-invalid': submitform && $v.nameEn.$error,
                                }"
                              ></b-form-input>
                              <div
                                v-if="submitform && $v.nameEn.$error"
                                class="invalid-tooltip"
                              >
                                <span v-if="!$v.nameEn.required">{{
                                  errormessage
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-6 col-md-3">
                            <div class="mb-3 position-relative">
                              ปีรุ่น:
                              <b-form-input
                                v-model="modelYear"
                                class="form-control"
                              ></b-form-input>
                            </div>
                          </div>
                          <div class="col-10 col-sm-6 col-md-3">
                            <div class="mb-3 position-relative">
                              ระบบเกียร์:
                              <multiselect
                                :options="transmissionOptions"
                                v-model="transmission"
                                :close-on-select="true"
                                :internal-search="true"
                                open-direction="bottom"
                                :show-labels="false"
                                label="nameTh"
                                placeholder=""
                              >
                                <span slot="noResult">ไม่พบข้อมูล</span>
                              </multiselect>
                            </div>
                          </div>
                          <div class="col-10 col-sm-6 col-md-3">
                            <div class="mb-3 position-relative">
                              น้ำมัน:
                              <multiselect
                                :options="fuelOptions"
                                v-model="fuel"
                                :close-on-select="true"
                                :internal-search="true"
                                open-direction="bottom"
                                :show-labels="false"
                                label="nameTh"
                                placeholder=""
                              >
                                <span slot="noResult">ไม่พบข้อมูล</span>
                              </multiselect>
                            </div>
                          </div>
                          <div class="col-6 col-sm-5 col-md-12">
                            <div class="mb-3 position-relative">
                              <b-button
                                v-if="this.$route.params.modelId == null"
                                class="btn mt-4 float-end"
                                variant="success"
                                type="submit"
                                @click="tooltipForm"
                              >
                                <!-- <i class="uil-search-alt"></i> -->
                                บันทึก
                              </b-button>
                              <b-button
                                v-if="this.$route.params.modelId != null"
                                class="btn mt-4 float-end"
                                variant="success"
                                type="submit"
                                @click="updateModel"
                              >
                                <!-- <i class="uil-search-alt"></i> -->
                                บันทึก
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
